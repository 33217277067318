import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function App(){
  const {isLoading, isAuthenticated, error, user, loginWithRedirect, logout}=useAuth0();

  if(isLoading){
    return <div>Loading...</div>;
  }
  
  if(error){
    return <div>Oops... {error.message}</div>;
  }
  
  if(isAuthenticated){
    return(
      <div>
        Hello {user.name}{' '}
        <button onClick={()=>logout({logoutParams:{returnTo:window.location.origin}})}>
          Log Out
        </button>
      </div>
    );
  } else {
    return <button onClick={()=> loginWithRedirect()}>Log In</button>;
  }
}

export default App;

/*
//import logo from './logo.svg';
import './App.css';
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SideMenuBar from './SideMenuBar.js';
import Footer from './Footer.js';
//import LoginButton from './components/LoginButton.js';
//import LogoutButton from './components/LogoutButton.js';

//import Auth0ProviderWithHistory from './auth0provider.js';

function App() {
  return (
  //  <Auth0ProviderWithHistory>
      <div className="App">

        <header className="App-header">

          <div className="content">
            <SideMenuBar/>
          </div>
          <main>
            <h1>Auth 0 Login</h1>
          </main>
          <p>
            Welcome.
          </p>
        </header>

        <Footer/>
      </div>
    //</Auth0ProviderWithHistory>
  );
}

export default App;
*/